import React, { useEffect } from 'react'
import { useContext } from '~/hooks'
import { navigate, PageProps } from 'gatsby'
import { Head, Login } from '~/components'

const Home = ({ location }: PageProps) => {
  const { token, profile } = useContext()
  useEffect(() => {
    if (token && profile) navigate('/dashboard/')
  }, [])

  return (
    <>
      <Head location={location} />
      <main className="bkg-main">
        <Login
          title="Acesso do cliente"
          input={[
            {
              label: 'CNPJ',
              name: 'login',
              mask: 'cnpj',
            },
            {
              label: 'Senha',
              name: 'password',
              type: 'password',
            },
          ]}
          btnRequest="Entrar"
          btnLoading="Carregando..."
          nextPage="dashboard"
          location={location}
          path={'login'}
        />
      </main>
    </>
  )
}

export default Home
